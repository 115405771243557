import React, { useState, useEffect, useRef } from 'react';
import { useAppData } from './context/DataContext';
import { ReactComponent as CloseIcon } from './CloseIcon.svg';
import { ReactComponent as GoogleLogo } from './GoogleLogo.svg';
import { Alert, Modal, ConfigProvider } from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import useRedirect from './useRedirect';


const secrets = require('./secrets.js');
const api = secrets.server_url;


async function createEnterpriseUser(email, password, setError) {
    try {
        const data = {
            email: email,
            password: password
        }
        const response = await axios.post(`${api}enterpriseUsers/`, data, {
            headers: {
                'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
            },
            withCredentials: true
        });
        if (response.data.data === 'email exists') {
            setError('This email already exists.');
        }
        return JSON.stringify(response.data);
    } catch (error) {
        console.error(error);
        throw error;
    }
}

async function addOrganizationToUser(organizationName, userEmail) {
    try {
        const userResponse = await axios.get(`${api}enterpriseUsers/?email=${encodeURIComponent(userEmail)}`, {
            headers: {
                'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY,
            },
        });

        if (userResponse.data && userResponse.data.data && userResponse.data.data.length > 0) {
            const currentOrganizations = userResponse.data.data.organizations || [];
            const updatedOrganizations = [...currentOrganizations, organizationName];

            const response = await axios.put(`${api}/enterpriseUsers?email=${encodeURIComponent(userEmail)}`, {
                organizations: updatedOrganizations,
            }, {
                headers: {
                    'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY,
                    'Content-Type': 'application/json',
                },
            });
        }
        else {
            console.error('User data not found');
        }
    }
    catch (error) {
        console.error(error);
        throw error;
    }
}

async function handleSignUp(name, newPassword, email, setError) {

    const response1 = await createEnterpriseUser(email, newPassword, setError)
    const response2 = await createOrganization(name, email, setError);
    await addOrganizationToUser(name, email)
    window.location.href = "https://ummahfy.com/organizerHome";
}

async function createOrganization(name, owner_email, setError) {
    try {
        const data = {
            name: name,
            email: owner_email
        }
        const response = await axios.post(`${api}organizations/`, data, {
            headers: {
                'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
            },
            withCredentials: true
        });
        if (response.data.data === 'email exists') {
            setError('This email already exists.');
        }
        return JSON.stringify(response.data);
    } catch (error) {
        console.error(error);
        throw error;
    }
}



function OrganizerSignIn(props) {


    const [isRegistered, setIsRegistered] = useState(true);
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [liPassword, setLiPassword] = useState('');
    const [name, setName] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMatchError, setPasswordMatchError] = useState('');
    const [emailError, setEmailError] = useState('');
    const orgNameInputRef = useRef(null);
    const { redirectToOrganizerHome } = useRedirect();
    const [emails, setEmails] = useState([]);
    const [orgs, setOrgs] = useState([]);

    const {
        information,
        informationWithPastEvents,
        loggedInUser,
        loggedInEmail,
        isAdmin,
        userInfo,
        userLoc,
        unapprovedEvents,
        selectedOrganization,
        selectedOrganizationInfo,
        managedOrganizations,
        users,
        enterpriseUsers
    } = useAppData();

    useEffect(() => {
        setEmails(enterpriseUsers.map(obj => obj.email));
    }, [enterpriseUsers])


    // useEffect(() => {
    //     async function fetchData() {
    //         try {
    //             // Fetch registered organizer emails
    //             const emailsResponse = await axios.get(`${api}enterpriseUsers/`, {
    //                 headers: {
    //                     'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
    //                 }
    //             });
    //             setEmails(emailsResponse.data.data.map(obj => obj.email));

    //             // Fetch organization names
    //             // console.log(`${api}organizations/`)
    //             const orgsResponse = await axios.get(`${api}organizations/`, {
    //                 headers: {
    //                     'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
    //                 }
    //             });
    //             // console.log(orgsResponse)
    //             setOrgs(orgsResponse.data.data.map(obj => obj.name));
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     }

    //     fetchData();
    // }, []);

    async function handleSignin(email, password, setError, props) {
        if (emails.indexOf(email) === -1) {
            setError('No user found with this email');
            return false;
        }

        const data = { password: password };
        try {
            const response = await axios.post(api + 'enterpriseUsers/', data, {
                withCredentials: true,
                headers: {
                    'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
                },
                params: {
                    email: email
                }
            });

            if (response.data.data === 'passwords match') {

                // Add a small delay before redirect
                // setTimeout(() => {
                redirectToOrganizerHome();
                // }, 100);
                return;
            } else {
                setError('Incorrect password');
            }
        } catch (error) {
            console.error('Error:', error);
            if (error.response && error.response.data.message === 'Invalid password') {
                setError('Incorrect password');
            } else {
                setError('An error occurred. Please try again.');
            }
        }
    }


    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        if (!email) {
            setIsRegistered(true);
        }
    };

    const handleEmailBlur = () => {
        if (email.trim()) {  // Only check if email is not empty
            if (!emails.includes(email)) {
                setIsRegistered(false);
                // Focus on orgNameInputRef if it's not null
                if (orgNameInputRef.current) {
                    orgNameInputRef.current.focus();
                }
            } else {
                setIsRegistered(true);
            }
        }
    };

    const handleClose = () => {
        props.setTrigger(false);
        setIsRegistered(true);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (isRegistered) {
            handleSignin(email, liPassword, setError);
        } else {
            if (newPassword !== confirmPassword) {
                setPasswordMatchError('Passwords do not match');
                return;
            }
            setPasswordMatchError('');
            setEmailError('');
            handleSignUp(name, newPassword, email, setEmailError);
        }
    };

    return (
        <>
            <ConfigProvider
                theme={{
                    components: {
                        Modal: {
                            // Add any modal-specific styling here
                        },
                    },
                }}
            >
                <Modal
                    open={props.trigger}
                    onCancel={handleClose}
                    footer={null}
                    className="signin-modal"
                    centered
                    title={
                        <div className="sign-title">
                            {!isRegistered ? (
                                <p>Organizer Sign Up</p>
                            ) : (
                                <p>Organizer Sign In</p>
                            )}
                        </div>
                    }
                >
                    <form onSubmit={handleSubmit}>
                        <div className="inputs">
                            <label htmlFor="email">Email</label>
                            <input
                                required
                                type="email"
                                id="email"
                                name="email"
                                className="long-input"
                                onChange={handleEmailChange}
                                onBlur={handleEmailBlur}
                                value={email}
                            />
                        </div>

                        {isRegistered ? (
                            <>
                                <div className="inputs">
                                    <label htmlFor="password">Password</label>
                                    <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        className="long-input"
                                        onChange={(e) => setLiPassword(e.target.value)}
                                        required
                                    />
                                </div>

                                {error && (
                                    <Alert
                                        message={error}
                                        type="error"
                                        showIcon
                                        style={{
                                            maxHeight: '42px',
                                            marginTop: '8px',
                                            borderRadius: '4px',
                                        }}
                                        closable
                                        onClose={() => setError('')}
                                    />
                                )}

                                <button type="submit" className="signin org-signin-btn">
                                    Organizer Sign In
                                </button>
                            </>
                        ) : (
                            <>
                                <div className="inputs">
                                    <label htmlFor="organizer">Organization Name</label>
                                    <input
                                        type="text"
                                        id="org-name"
                                        name="org-name"
                                        className="long-input"
                                        required
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        ref={orgNameInputRef}
                                    />
                                </div>

                                <div className="inputs">
                                    <label htmlFor="password">Password</label>
                                    <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        className="long-input"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className="inputs">
                                    <label htmlFor="confirm">Confirm Password</label>
                                    <input
                                        type="password"
                                        id="confirm"
                                        name="confirm"
                                        className="long-input"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                    />
                                </div>

                                {passwordMatchError && (
                                    <Alert
                                        message={passwordMatchError}
                                        type="error"
                                        showIcon
                                        style={{
                                            maxHeight: '42px',
                                            marginTop: '8px',
                                            borderRadius: '4px',
                                        }}
                                        closable
                                        onClose={() => setPasswordMatchError('')}
                                    />
                                )}

                                {emailError && (
                                    <Alert
                                        message={emailError}
                                        type="error"
                                        showIcon
                                        style={{
                                            maxHeight: '42px',
                                            marginTop: '8px',
                                            borderRadius: '4px',
                                        }}
                                        closable
                                        onClose={() => setEmailError('')}
                                    />
                                )}

                                <button type="submit" className="signin org-signin-btn">
                                    Create Account
                                </button>
                            </>
                        )}
                    </form>
                </Modal>
            </ConfigProvider>
        </>
    );
}

export default OrganizerSignIn;
import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeInOut = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

const fadeOut = keyframes`
    0% { opacity: 1; }
    100% { opacity: 0; }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F7F9FB;
  min-height: 100vh;
`;

const UmmahfyLogo = styled.svg`
  width: 300px;
  opacity: 0;
  animation: ${fadeInOut} 2s infinite ease-in-out;
`;

const Loading = (props) => {
  return (
    <Container>
      <UmmahfyLogo
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 612 370.2"
      >
        <defs>
          <style>
            {`.cls-1 {
                filter: url(#drop-shadow-1);
              }
              .cls-2 {
                fill: url(#linear-gradient);
              }
              .cls-3 {
                fill: #fff;
              }`}
          </style>
          <linearGradient 
            id="linear-gradient" 
            x1="-296.39" 
            y1="-353.08" 
            x2="148.68" 
            y2="92" 
            gradientTransform="translate(406.21 291.79) scale(1.35 .82)" 
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#2c489b"/>
            <stop offset=".06" stopColor="#294594"/>
            <stop offset=".48" stopColor="#183268"/>
            <stop offset=".81" stopColor="#0d274d"/>
            <stop offset="1" stopColor="#0a2343"/>
          </linearGradient>
          <filter id="drop-shadow-1" x="16.32" y="16.08" width="579.36" height="337.92" filterUnits="userSpaceOnUse">
            <feOffset dx="0" dy="0"/>
            <feGaussianBlur result="blur" stdDeviation="20"/>
            <feFlood floodColor="#0a2343" floodOpacity=".4"/>
            <feComposite in2="blur" operator="in"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <rect className="cls-2" x="0" y="-.25" width="612.84" height="370.71" rx="30" ry="30"/>
        <g className="cls-1">
          <path className="cls-3" d="M531.53,125.79l-43.75-43.75c-3.56-3.56-8.4-5.57-13.44-5.57h-60.11c-7.5,0-13.59,6.08-13.59,13.59v190.34c0,7.5,6.08,13.59,13.59,13.59h54.4c7.5,0,13.59-6.08,13.59-13.59v-117.84c0-7.5,6.08-13.59,13.59-13.59h26.12c12.1,0,18.16-14.63,9.61-23.19Z"/>
          <path className="cls-3" d="M362.13,157.82h-108.85c-7.51,0-13.59,6.09-13.59,13.59v54.08c0,6.49-4.4,12.38-10.76,13.66-8.75,1.77-16.43-4.87-16.43-13.31V90.5c0-7.51-6.09-13.59-13.59-13.59h-108.85c-7.51,0-13.59,6.09-13.59,13.59v108.85c0,7.51,6.09,13.59,13.59,13.59h54.43c7.51,0,13.59,6.09,13.59,13.59v53.6c0,7.51,6.09,13.59,13.59,13.59h40.82v.13h149.63c7.51,0,13.59-6.09,13.59-13.59v-108.85c0-7.51-6.09-13.59-13.59-13.59ZM158.09,144.93c0,7.51-6.09,13.59-13.59,13.59h-.02c-7.51,0-13.59-6.09-13.59-13.59v-.02c0-7.51,6.09-13.59,13.59-13.59h.02c7.51,0,13.59,6.09,13.59,13.59v.02ZM321.31,225.85c0,7.51-6.09,13.59-13.59,13.59h-.02c-7.51,0-13.59-6.09-13.59-13.59v-.02c0-7.51,6.09-13.59,13.59-13.59h.02c7.51,0,13.59,6.09,13.59,13.59v.02Z"/>
          <path className="cls-3" d="M368.97,76.21h-22.05c-3.74,0-6.41,3.15-6.49,6.89-.07,3.67-3.07,6.62-6.75,6.62h-1.87c-3.74,0-6.41-3.15-6.49-6.89-.07-3.67-3.07-6.62-6.75-6.62h-22.01c-3.74,0-6.41,3.15-6.49,6.89-.07,3.67-3.07,6.62-6.75,6.62h-1.87c-3.74,0-6.41-3.15-6.49-6.89-.07-3.67-3.07-6.62-6.75-6.62h-21.76c-3.73,0-6.76,3.02-6.76,6.76v40.91c0,3.73,3.02,6.75,6.75,6.75h122.53c3.73,0,6.75-3.02,6.75-6.75v-40.91c0-3.73-3.02-6.75-6.75-6.75Z"/>
        </g>
      </UmmahfyLogo>
    </Container>
  );
};

export default Loading;
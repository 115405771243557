import React, { useState, useEffect } from 'react';
import { Upload, X } from 'lucide-react';
import { ReactComponent as UploadIcon} from './UploadFile.svg';

const FileUpload = ({ onFileSelect, existingImage }) => {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(existingImage || null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // Update preview when existingImage changes
  useEffect(() => {
    if (existingImage) {
      setPreview(existingImage);
    }
  }, [existingImage]);

  useEffect(() => {
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
        setIsUploading(true);
        setTimeout(() => setIsUploading(false), 500);
      };
      reader.readAsDataURL(file);
    }
  }, [file]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.size <= 50 * 1024 * 1024) {
      setFile(selectedFile);
      onFileSelect(selectedFile);
    } else {
      alert('Please select a file smaller than 50 MB');
    }
  };

  const handleRemoveFile = () => {
    setIsDeleting(true);
    setTimeout(() => {
      setFile(null);
      setPreview(null);
      setIsDeleting(false);
      onFileSelect(null);
    }, 300);
  };

  return (
    <>
      {!preview ? (
        <label htmlFor="file-upload" className="file-upload-label">
          <div className="file-upload-content">
            <UploadIcon className="upload-icon" />
            <p className="upload-text">
              <span className="upload-text-bold">Upload your Flyer Here</span>
            </p>
            <p className="upload-subtext">Maximum file size: 50 MB</p>
            <p className="upload-subtext">Supported formats: JPG, PNG</p>
          </div>
          <input 
            id="file-upload" 
            type="file" 
            className="file-input" 
            onChange={handleFileChange}
            accept=".jpg,.jpeg,.png"
            style={{ display: "none" }}
          />
        </label>
      ) : (
        <div className={`file-preview ${isUploading ? 'uploading' : ''} ${isDeleting ? 'deleting' : ''}`}>
          <img src={preview} alt="File preview" className="image-preview" />
          <button onClick={handleRemoveFile} className="remove-file">
            <X size={24} />
          </button>
        </div>
      )}
    </>
  );
};

export default FileUpload;
import axios from 'axios';

const secrets = require('./secrets');
const api = secrets.server_url;

// Create configured axios instance
const apiClient = axios.create({
  baseURL: api, // This is your secrets.server_url
  headers: {
    'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
  },
  withCredentials: true
});

async function getCurrentUser(email) {
  try {
    const response = await apiClient.get(`users/${email}`);
    return response?.data?.data?.[0] || null;
  } catch (error) {
    console.error('Failed to fetch current user:', error);
    return null;
  }
}

async function getCurrentEnterpriseUser(email) {
  try {
    const response = await apiClient.get('enterpriseUsers/', {
      params: { email }
    });
    return response?.data?.data?.[0] || null;
  } catch (error) {
    console.error('Failed to fetch enterprise user:', error);
    return null;
  }
}

async function getAllUsers() {
  try {
    const [regularUsers, enterpriseUsers] = await Promise.all([
      apiClient.get('users/'),
      apiClient.get('enterpriseUsers/')
    ]);

    return {
      users: regularUsers?.data?.data || [],
      enterpriseUsers: enterpriseUsers?.data?.data || []
    };
  } catch (error) {
    console.error('Failed to fetch all users:', error);
    return { users: [], enterpriseUsers: [] };
  }
}

async function getHomePageEvents(userLoc) {
  console.log('getHomePageEvents')
  try {

    let response

    if (userLoc.locationAllowed) {
      response = await apiClient.get("events/", {
        params: {
          limit: 10,
          upcoming: true,
          lat: userLoc?.lat,
          lon: userLoc?.lon,
          radius: 100
        }
      });
    } else {
      response = await apiClient.get("events/", {
        params: {
          limit: 10,
          upcoming: true,
        }
      });
    }
    // console.log(response)

    const processedEvents = processEssentialData(response.data);

    processedEvents.sort((a, b) => {
      // Helper function to get the relevant date for sorting
      const getRelevantDate = (event) => {
        if (!event.dates || event.dates.length === 0) return 'N/A';

        // For one-time events, use the first date
        if (event.frequency === 'Once') {
          return event.dates[0];
        } else {
          return event.dates[event.dateIndex];
        }

      };

      const dateStrA = getRelevantDate(a);
      const dateStrB = getRelevantDate(b);

      // Handle 'N/A' cases
      if (dateStrA === 'N/A') return 1;
      if (dateStrB === 'N/A') return -1;

      // Compare dates
      return new Date(dateStrA) - new Date(dateStrB);
    });

    if (userLoc?.lat && userLoc?.lon) {
      await processLocations(processedEvents, userLoc.lat, userLoc.lon);
    }

    console.log(processedEvents)
    return processedEvents.filter(item =>
      !item.past &&
      item.isApproved &&
      !item.isPrivate
    );
  } catch (error) {
    console.error('Failed to fetch homepage events:', error);
    throw new Error('Failed to fetch homepage events');
  }
}

async function getSingleEvent(eventId) {
  try {
    console.log(eventId)
    const response = await apiClient.get(`events/${eventId}`);
    console.log(response)
    const processedEvents = processEssentialData([response.data.data]);
    return processedEvents[0];
  } catch (error) {
    console.error('Failed to fetch event:', error);
    throw new Error('Failed to fetch event');
  }
}

// in information.service.js
async function getBaseData() {
  try {
    // Only get session data and location in parallel
    const [loggedInUser, loggedInEmail, userLoc] = await Promise.all([
      fetchSessionData(),
      fetchSessionEmail(),
      getUserLocation()
    ]);

    // Return basic data - user and org details will be handled separately
    console.log(loggedInUser)
    return {
      loggedInUser: loggedInUser,
      loggedInEmail: loggedInEmail,
      userLoc
    };

  } catch (error) {
    console.error('Failed to fetch base data:', error);
    throw new Error('Failed to fetch base data');
  }
}

async function getAllEvents() {
  try {
    const response = await apiClient.get("events/");

    const processedEvents = processEssentialData(response.data);

    processedEvents.sort((a, b) => {
      // Helper function to get the relevant date for sorting
      const getRelevantDate = (event) => {
        if (!event.dates || event.dates.length === 0) return 'N/A';

        // For one-time events, use the first date
        if (event.frequency === 'Once') {
          return event.dates[0];
        } else {
          return event.dates[event.dateIndex];
        }
      };

      const dateStrA = getRelevantDate(a);
      const dateStrB = getRelevantDate(b);

      // Handle 'N/A' cases
      if (dateStrA === 'N/A') return 1;
      if (dateStrB === 'N/A') return -1;

      // Compare dates
      return new Date(dateStrA) - new Date(dateStrB);
    });

    return processedEvents;
  } catch (error) {
    console.error('Failed to fetch events:', error);
    throw new Error('Failed to fetch all events');
  }
}

const fetchSessionData = async () => {
  const response = await apiClient.get('getLoggedInUser/');
  return response.data;
};

const fetchSessionEmail = async () => {
  const response = await apiClient.get('getLoggedInEmail/');
  return response.data;
};

const getUserDetails = async (loggedInUser, loggedInEmail) => {
  if (loggedInUser && loggedInUser.data === "Enterprise User") {
    const response = await apiClient.get('enterpriseUsers/', {
      params: {
        email: loggedInEmail.data
      }
    });

    if (response && response.data)
      return response.data.data[0]
    else
      return null
  }

  if (!loggedInUser.data) {
    return false
  }

  const response = await apiClient.get(`users/${loggedInEmail.data}`);

  if (response && response.data)
    return response.data.data[0]
  else
    return null
}

async function getOrganizationInfo(loggedInUser, loggedInEmail) {
  if (loggedInUser?.data !== "Enterprise User") {
    return {
      selectedOrganization: "",
      selectedOrganizationInfo: null,
      managedOrganizations: []
    };
  }

  try {
    const [selectedOrgResponse, userDetailsResponse] = await Promise.all([
      apiClient.get('getSelectedOrganization/'),
      apiClient.get('enterpriseUsers/', {
        params: {
          email: loggedInEmail.data
        }
      })
    ]);


    const selectedOrganization = selectedOrgResponse?.data?.data ||
      userDetailsResponse?.data?.data?.[0]?.organizations?.[0] || "";

    // If we have a selected organization, get its info
    if (selectedOrganization) {
      const orgResponse = await apiClient.get('organizations/', {
        params: {
          name: selectedOrganization
        }
      })


      return {
        selectedOrganization,
        selectedOrganizationInfo: orgResponse?.data?.data?.[0] || null,
        managedOrganizations: [] // We'll populate this in a moment
      };
    }
  } catch (error) {
    console.error('Error fetching organization info:', error);
    return {
      selectedOrganization: "",
      selectedOrganizationInfo: null,
      managedOrganizations: []
    };
  }
}

function processEssentialData(events) {
  const currentDateTime = new Date();

  return events.map(event => {
    const processed = {
      ...event,
      tags: event.tags || [],
      dateIndex: -1,
      dates: event.dates || []
    };

    // Fix time formats
    if (processed.start_datetime) {
      processed.start_datetime = processed.start_datetime.replace(
        /T(\d{1}):/,
        (match, p1) => `T0${p1}:`
      );
    }


    if (event.frequency === "Once") {
      processed.dateIndex = 0
    } else {
      processed.dateIndex = -1

      if (processed.dates?.length > 0) {
        const currentDate = new Date().setHours(0, 0, 0, 0);
        for (let i = 0; i < processed.dates.length; i++) {
          const arrayDate = new Date(processed.dates[i]).setHours(0, 0, 0, 0);
          if (arrayDate >= currentDate) {
            processed.dateIndex = i;
            break;
          }
        }
        if (processed.dateIndex === -1) {
          processed.dateIndex = processed.dates.length - 1;
        }
      }
    }

    // Set lastDate using original logic
    processed.lastDate = processed.dates[processed.dates.length - 1]

    if (processed.lastDate === 'N/A') {
      processed.lastDate = processed.start_datetime;
    }

    // Fix lastDate time format
    if (processed.lastDate) {
      processed.lastDate = processed.lastDate.replace(
        /T(\d{1}):/,
        (match, p1) => `T0${p1}:`
      );
    }

    // Convert image URLs
    if (processed.img) {
      processed.img = processed.img.replace(
        "https://ummahfy-event-images.s3.us-east-2.amazonaws.com",
        "https://img.ummahfy.com"
      );
    }

    // Format start date
    let startFormatted = "";
    if (processed.start_datetime) {
      const startDate = new Date(processed.start_datetime);
      const dateOptions = {
        weekday: 'long',
        month: 'short',
        day: 'numeric'
      };

      const timeOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      };

      try {
        const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(startDate);
        const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(startDate);
        const timeWithoutMinutes = formattedTime.replace(':00', '').toLowerCase();
        startFormatted = `${formattedDate}  •  ${timeWithoutMinutes}`;
      } catch {
        startFormatted = "Mon, Jan 1 • 12am";
      }
    }

    // Format end date
    let endFormatted = "";
    if (processed.lastDate) {
      const endDate = new Date(processed.lastDate);
      const dateOptions = {
        weekday: 'long',
        month: 'long',
        day: 'numeric'
      };

      const timeOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      };

      try {
        const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(endDate);
        const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(endDate);
        const timeWithoutMinutes = formattedTime.replace(':00', '').toLowerCase();
        endFormatted = `${formattedDate} | ${timeWithoutMinutes}`;
      } catch {
        endFormatted = "Monday, January 1st | 12am";
      }
    }

    // Create timeArray using original format
    processed.timeArray = [startFormatted, endFormatted];

    // Check if event is past using original logic
    const endDateTime = new Date(processed.lastDate);
    // if(processed.event_id === 4947){
    //   console.log("timings")
    //   console.log(endDateTime)
    //   console.log(currentDateTime)
    // }
    processed.past = endDateTime < currentDateTime;

    return processed;
  });
}

async function getUserLocation() {
  // Default location if something goes wrong
  const defaultLocation = {
    city: "Chicago",
    lat: 41.8781,
    lon: 87.6298,
  };

  if (!navigator.geolocation) {
    return defaultLocation;
  }

  try {
    const position = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: true
      });
    });

    const { latitude, longitude } = position.coords;

    try {
      const cityResponse = await apiClient.get('getCityFromCoords', {
        params: { lat: latitude, lon: longitude }
      });

      return {
        city: cityResponse.data.data,
        lat: latitude,
        lon: longitude,
        locationAllowed: true
      };
    } catch (error) {
      console.error('Error fetching city:', error);
      // Return coordinates but with default city
      return {
        ...defaultLocation,
        lat: latitude,
        lon: longitude,
        locationAllowed: false
      };
    }
  } catch (error) {
    console.error('Location access failed:', error);
    return defaultLocation;
  }
}


function calculateDistance(lat1, lon1, lat2, lon2) {
  const earthRadiusMiles = 3963.0;

  const lat1Rad = lat1 * (Math.PI / 180);
  const lat2Rad = lat2 * (Math.PI / 180);
  const lon1Rad = lon1 * (Math.PI / 180);
  const lon2Rad = lon2 * (Math.PI / 180);

  const distance =
    earthRadiusMiles *
    Math.acos(
      Math.sin(lat1Rad) * Math.sin(lat2Rad) +
      Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.cos(lon2Rad - lon1Rad)
    );

  return distance;
}

async function getEventLoc(placeName) {
  if (!placeName) return null;

  try {
    // Remove the encodeURIComponent here since axios will handle the encoding
    const response = await apiClient.get('getEventLoc/', {
      params: {
        placeName: placeName.trim()  // Just trim, don't encode
      }
    });

    console.log('Location response:', response.data);

    if (response.data.candidates && response.data.candidates.length > 0) {
      const location = response.data.candidates[0].geometry.location;
      return {
        latitude: location.lat,
        longitude: location.lng
      };
    }
    return null;
  } catch (error) {
    console.error('Error getting event location:', error);
    return null;
  }
}

async function processLocations(informationArray, userLatitude, userLongitude) {
  if (!userLatitude || !userLongitude) {
    // If no user location, mark all distances as null
    informationArray.forEach(item => {
      item.distanceFromUser = null;
    });
    return informationArray;
  }

  informationArray.forEach(item => {
    if (!item.latitude || !item.longitude) {
      item.distanceFromUser = null;
      return;
    }

    try {
      item.distanceFromUser = calculateDistance(
        userLatitude,
        userLongitude,
        item.latitude,
        item.longitude
      );
    } catch {
      item.distanceFromUser = null;
    }
  });

  return informationArray;
}

async function getSelectedOrgInfo(email) {
  try {
    // Get selected organization and user details in parallel
    const [selectedOrgResponse, userDetailsResponse] = await Promise.all([
      apiClient.get('getSelectedOrganization/'),
      apiClient.get('enterpriseUsers/', {
        params: { email }
      })
    ]);

    const selectedOrganization = selectedOrgResponse?.data?.data ||
      userDetailsResponse?.data?.data?.[0]?.organizations?.[0] || "";

    // If we have a selected organization, get its info
    if (selectedOrganization) {
      const orgResponse = await apiClient.get('organizations/', {
        params: {
          name: selectedOrganization
        }
      });

      return {
        selectedOrganization,
        selectedOrganizationInfo: orgResponse?.data?.data?.[0] || null,
        managedOrganizations: [] // We'll get all managed orgs in second load
      };
    }

    return {
      selectedOrganization: "",
      selectedOrganizationInfo: null,
      managedOrganizations: []
    };
  } catch (error) {
    console.error('Error fetching selected organization info:', error);
    return {
      selectedOrganization: "",
      selectedOrganizationInfo: null,
      managedOrganizations: []
    };
  }
}

async function getAllOrganizations() {
  try {
    const response = await apiClient.get('organizations/');
    return response?.data?.data || [];
  } catch (error) {
    console.error('Failed to fetch organizations:', error);
    return [];
  }
}

async function getDateIndex(dates) {
  let dateIndex = -1

  if (dates?.length > 0) {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    for (let i = 0; i < dates.length; i++) {
      const arrayDate = new Date(dates[i]).setHours(0, 0, 0, 0);
      if (arrayDate >= currentDate) {
        dateIndex = i;
        break;
      }
    }
    if (dateIndex === -1) {
      dateIndex = dates.length - 1;
    }
  }

  return dateIndex
}

// if(loggedInEmail){
//   window.heap.identify(loggedInEmail);

// }

export {
  getHomePageEvents,
  getSingleEvent,
  getBaseData,
  getAllEvents,
  processLocations,
  getEventLoc,
  processEssentialData,
  calculateDistance,
  getUserLocation,
  getUserDetails,
  getOrganizationInfo,
  fetchSessionData,
  fetchSessionEmail,
  getCurrentUser,
  getCurrentEnterpriseUser,
  getAllUsers,
  getSelectedOrgInfo,
  getAllOrganizations,
  getDateIndex
};
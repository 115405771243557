import React, { useState, useEffect, useContext } from 'react';
import { useAppData } from './context/DataContext';

import { useLocation } from 'react-router-dom';

const Ticket = () => {
    const location = useLocation();

    const params = new URLSearchParams(location.search);
    const userName = params.get('userName');
    const userEmail = params.get('userEmail');
    const eventName = params.get('eventName');

    return (
        <div>
            <h1>Attendee Name: {userName}</h1>
            <h1>Attendee Email: {userEmail}</h1>
            <h1>Event Name: {eventName}</h1>
        </div>
    );
};

export default Ticket;

// src/context/DataContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios'

const secrets = require('../secrets.js');
const api = secrets.server_url;

export const DataContext = createContext();

export function DataProvider({ children }) {
  const [appData, setAppData] = useState({
    information: [],
    informationWithPastEvents: [],
    loggedInUser: null,
    loggedInEmail: null,
    isAdmin: null,
    userInfo: null,
    userLoc: null,
    unapprovedEvents: [],
    selectedOrganization: "",
    selectedOrganizationInfo: null,
    managedOrganizations: [],
    currentUser: null,
    users: [],
    enterpriseUsers: [],
    usersLoaded: false
  });

  // Provide a function to update specific fields
  const updateData = (newData) => {
    console.log('Updating context data:', newData); // Debug log
    setAppData(prevData => {
      const updated = {
        ...prevData,
        ...(typeof newData === 'function' ? newData(prevData) : newData)
      };
      console.log('Updated context data:', updated); // Debug log
      return updated;
    });
  };

  const logout = async () => {
    try {
      const response = await axios.get(`${api}logout`, {
        withCredentials: true,
        headers: {
          'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
        }
      });

      if (response.status === 200 && response.data.data) {
        // Clear all context data first
        // setAppData({
        //   information: [],
        //   informationWithPastEvents: [],
        //   loggedInUser: null,
        //   loggedInEmail: null,
        //   isAdmin: null,
        //   userInfo: null,
        //   userLoc: null,
        //   unapprovedEvents: [],
        //   selectedOrganization: "",
        //   selectedOrganizationInfo: null,
        //   managedOrganizations: [],
        //   currentUser: null,
        //   users: [],
        //   enterpriseUsers: [],
        //   usersLoaded: false
        // });

        // Use setTimeout to ensure state is cleared before redirect
        setTimeout(() => {
          window.location.href = '/';
        }, 100);
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  useEffect(() => {
    console.log('Context data changed:', appData); // Debug log
  }, [appData]);

  return (
    <DataContext.Provider 
      value={{
        ...appData,
        setAppData: updateData,
        logout
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

// Custom hook for components that need to access data
export function useAppData() {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useAppData must be used within a DataProvider');
  }
  return context;
}
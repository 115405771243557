import EventCard from "./EventCard";
import React, { useState, useEffect, useContext } from 'react';
import { useAppData } from './context/DataContext';

import { ReactComponent as Calendar } from './Calendar.svg';
import { ReactComponent as Location } from './Location.svg';
import { Link } from "react-router-dom";
import { useParams, useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import axios from 'axios';


import Footer from './Footer';

const secrets = require("./secrets.js");
const api = secrets.server_url;

const ResetPassword = () => {

    const {
    information,
    informationWithPastEvents,
    loggedInUser,
    loggedInEmail,
    isAdmin,
    userInfo,
    userLoc,
    unapprovedEvents,
    selectedOrganization,
    selectedOrganizationInfo,
    managedOrganizations
  } = useAppData();

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const token = queryParams.get('token');
    const userType = queryParams.get('userType');
    const email = queryParams.get('email');
    const organization = queryParams.get('organization');
    const requestType = queryParams.get('requestType');

    console.log(token)
    console.log(userType)
    console.log(email)

    // Check if required URL parameters exist
    if (!token || !userType || !email) {
        // return <div>No Access</div>;
        window.location.href = "/";
    }

    async function handleResetPassword() {
        if (password !== confirmPassword) {
            console.error("passwords dont match")
        }
        else {
            try {
                console.log("calling validateToken")
                const response = await axios.post(`${api}validateTokenAndResetPassword`, {
                    token: token,
                    email: email,
                    userType: userType,
                    newPassword: password,
                    organization: organization,
                    requestType: requestType
                },
                    {
                        headers: {
                            'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
                        }
                    })
                    
            }
            catch (error) {
                console.error(error)
            }
            
        }
       
    }

    return (
        <>
            <div class="wrapper">

                <Navbar> </Navbar>

                <div class="forgotpassword-page">
                    <form onSubmit={handleResetPassword} class="form-forgot-pass">
                        {/* Text input */}

                        <h1> Create a New Password</h1>

                        <div class="rp-enter-pass">
                            <label> Password</label>
                            <input
                                class="rp-pass-input"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}

                            />
                        </div>



                        <div class="rp-confirm-pass">
                            <label> Confirm Password </label>
                            <input
                                class="rp-pass-input"
                                type="password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}

                            />
                        </div>



                        {/* Submit button */}
                        <button type="submit" class="rp-submit">Submit</button>
                    </form>
                </div>
            </div>
            <Footer> </Footer>
        </>
    );
};

export default ResetPassword;

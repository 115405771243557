import React, { useState, useEffect, useContext } from 'react';
import { useAppData } from './context/DataContext';


const DaySelector = ({ onChange, isMonthly }) => {
  const [selectedTags, setSelectedTags] = useState([]);

  const dayMappings = {
    'Mon': 'Monday',
    'Tues': 'Tuesday',
    'Wed': 'Wednesday',
    'Thur': 'Thursday',
    'Fri': 'Friday',
    'Sat': 'Saturday',
    'Sun': 'Sunday'
  };

  useEffect(() => {
    let fullDays = selectedTags.map(day => dayMappings[day] || day);
    onChange(fullDays);
  }, [selectedTags, onChange]);

  const tags = [
    'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'
  ];

  const toggleTag = (tag) => {
    if (isMonthly) {
      setSelectedTags([tag]); // Only allow one selection in monthly mode
    } else {
      setSelectedTags(prevTags =>
        prevTags.includes(tag)
          ? prevTags.filter(t => t !== tag)
          : [...prevTags, tag]
      );
    }
  };

  return (
    <>
      {tags.map(tag => (
        <div
          key={tag}
          onClick={() => toggleTag(tag)}
          className={`day-tag ${selectedTags.includes(tag) ? 'selected' : ''}`}
        >
          {tag}
        </div>
      ))}
    </>
  );
};

export default DaySelector;
import React, { useState } from 'react';
import { useAppData } from './context/DataContext';
import { Modal, ConfigProvider, Form } from 'antd';
import { ReactComponent as SuccessIcon } from "./SuccessIcon.svg";
import CustomFormModal from './CustomFormModal.js';

const secrets = require("./secrets.js");
const api = secrets.server_url;

function EditRSVP(props) {
    const {
        information,
        informationWithPastEvents,
        loggedInUser,
        loggedInEmail,
        userInfo,
        userLoc,
        unapprovedEvents,
        selectedOrganization,
        selectedOrganizationInfo,
        managedOrganizations
    } = useAppData();

    const [customForm, setCustomForm] = useState(false);
    const [form] = Form.useForm();

    const ensureHttps = (url) => {
        if (!url) return url;
        if (!url.startsWith("https://") && !url.startsWith("http://")) {
            return "https://" + url;
        }
        return url;
    };

    const isPaidEvent = props.info?.tickets_link?.toLowerCase().includes('stripe');
    const hasExternalRegistration =
        props.info?.tickets_link !== null &&
        props.info?.tickets_link !== "N/A" &&
        props.info?.tickets_link !== "";

    const externalLink = props.info?.tickets_link ? ensureHttps(props.info.tickets_link) : "";

    const handleCustomFormCancel = () => {
        setCustomForm(false);
    };

    const handleCustomFormSubmit = (values) => {
        console.log('Form values:', values);
        if (props.info.tickets_link) {
            window.location.href = props.info.tickets_link;
        }
        setCustomForm(false);
    };

    return (
        <>
            <ConfigProvider
                theme={{
                    components: {
                        Modal: {
                            titleFontSize: 20,
                        },
                    },
                }}
            >
                <Modal
                    open={props.trigger}
                    onCancel={() => props.setTrigger(false)}
                    footer={null}
                    width={400}
                    centered
                    title={isPaidEvent ? "Paid Event Registration" : "Update Your RSVP"}
                    className="registration-modal"
                >
                    <div>
                        {props.info.customFormQuestions && props.info.customFormQuestions.length > 0 ? (
                            <div>
                                <p className="rm-subtext cancel-subtext">
                                    Update your responses or cancel your registration below.
                                </p>
                                <div className="next-step-share">
                                    <button
                                        className="next-step-registration"
                                        onClick={() => {
                                            props.setTrigger(false);
                                            setCustomForm(true);
                                        }}
                                    >
                                        Edit Form Info
                                    </button>
                                    <button
                                        className="next-step-registration cancel-button"
                                        onClick={async () => {
                                            await props.handleUnRegister(props.id);
                                            window.location.reload();
                                        }}
                                    >
                                        Cancel Registration
                                    </button>
                                </div>
                            </div>
                        ) : isPaidEvent ? (
                            <div>
                                <p className="rm-subtext cancel-subtext">
                                    This is a paid event. For refund requests or registration changes,
                                    please contact the event organizer directly.
                                </p>
                                {props.info?.contact_email && (
                                    <p className="rm-subtext">
                                        Contact: {props.info.contact_email}
                                    </p>
                                )}
                                <button
                                    className="next-step-registration"
                                    onClick={() => props.setTrigger(false)}
                                >
                                    Close
                                </button>
                            </div>
                        ) : (
                            <div>
                                <p className="rm-subtext cancel-subtext">
                                    {hasExternalRegistration
                                        ? "Complete your registration with Next Steps or cancel your RSVP below"
                                        : "Let us know if your plans have changed by canceling your RSVP below."}
                                </p>
                                {hasExternalRegistration ? (
                                    <div className="next-step-share">
                                        <a
                                            href={externalLink}
                                            className="next-step-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <button className="next-step-registration">
                                                Next Steps Here
                                            </button>
                                        </a>
                                        <button
                                            className="next-step-registration cancel-button"
                                            onClick={async () => {
                                                await props.handleUnRegister(props.id);
                                                window.location.reload();
                                            }}
                                        >
                                            Cancel Registration
                                        </button>
                                    </div>
                                ) : (
                                    <button
                                        className="next-step-registration cancel-button"
                                        onClick={async () => {
                                            await props.handleUnRegister(props.id);
                                            window.location.reload();
                                        }}
                                    >
                                        Cancel Registration
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                </Modal>

                {props.info.customFormQuestions && props.info.customFormQuestions.length > 0 && (
                    <CustomFormModal
                        isOpen={customForm}
                        onClose={handleCustomFormCancel}
                        onSubmit={handleCustomFormSubmit}
                        form={form}
                        info={props.info}
                        loggedInEmail={loggedInEmail}
                        isRegistered={props.isRegistered}
                        isStripe={props.isStripe}
                    />
                )}

            </ConfigProvider>
        </>
    );
}

export default EditRSVP;
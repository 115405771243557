import React from 'react';
import { Modal, ConfigProvider } from 'antd';
import { useAppData } from './context/DataContext';

const AttendeesModal = ({
    isVisible,
    onCancel,
    info,
    dateIndex,
    getAttendeeDisplay,
}) => {
    const {
        users
    } = useAppData();

    function getNameFromEmail(inputEmail) {
        const userIndex = users.findIndex(user => user.email === inputEmail);
        return userIndex !== -1 ? users[userIndex].username : inputEmail;
    }

    console.log("these are the users", users)

    return (
        <ConfigProvider
            theme={{
                components: {
                    Modal: {
                        titleFontSize: 20,
                    },
                },
            }}
        >
            <Modal
                className="ce-modal"
                title={`Attendees List (${getAttendeeDisplay(info, dateIndex)})`}
                open={isVisible}
                onCancel={onCancel}
                footer={null}
                closable={true}
                maskClosable={true}
                centered
            >
                <div className="attendees-container">
                    {(!info?.registered?.[0] || info.registered[0].length === 0) ? (
                        <div className="no-attendees">
                            <p className="no-attendees-text">No attendees registered yet</p>
                        </div>
                    ) : (
                        info.registered[0].map((email, index) => (
                            <div key={index} className="attendee-card">
                                <div className="attendee-avatar">
                                    <span className="avatar-text">
                                        {getNameFromEmail(email).charAt(0).toUpperCase()}
                                    </span>
                                </div>
                                <div class="name-email-div">
                                    <span> <b> {getNameFromEmail(email)}</b></span>
                                    <span>{email}</span>
                                </div>

                            </div>
                        ))
                    )}
                </div>
            </Modal>
        </ConfigProvider>
    );
};

export default AttendeesModal;
import React, { useState, useEffect } from 'react';
import { useAppData } from './context/DataContext';
import { Link } from "react-router-dom";
import { Menu } from 'antd';
import { 
    UserOutlined, 
    SearchOutlined, 
    PlusOutlined, 
    LogoutOutlined, 
    LoginOutlined, 
    AppstoreOutlined, 
    SettingOutlined 
} from '@ant-design/icons';
import Signin from './Signin';
import OrganizerSignIn from "./OrganizerSignIn";
import NavbarLogo from './NavbarLogo.svg';
import { ReactComponent as Avatar } from "./IconUmmahfy.svg";
import { Dropdown } from 'antd';

function Navbar() {
    const {
        loggedInUser,
        loggedInEmail,
        userInfo,
        logout  // Get logout from context
    } = useAppData();

    const [isAdmin, setIsAdmin] = useState(userInfo ? userInfo.isAdmin: false);

    const [buttonSignin, setButtonSignin] = useState(false);
    const [organizerSignin, setOrganizerSignin] = useState(false);
    const [showMenu, setShowMenu] = useState(window.innerWidth > 890);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setShowMenu(window.innerWidth > 890);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
    const closeDrawer = () => setIsDrawerOpen(false);

    // const handleLogout = async (e) => {
    //     if (e) e.preventDefault();
    //     await logout();
    // };


    const getMobileMenuItems = () => {
        if (loggedInUser?.data) {
            return [
                isAdmin && {
                    key: 'admin',
                    icon: <SettingOutlined />,
                    label: <Link to="/adminPanel" onClick={closeDrawer}>Admin</Link>,
                },
                (loggedInUser.data === "Enterprise User") && {
                    key: 'organizer',
                    icon: <AppstoreOutlined />,
                    label: <Link to="/organizerHome" onClick={closeDrawer}>Organizer Page</Link>,
                },
                {
                    key: 'events',
                    icon: <SearchOutlined />,
                    label: <Link to="/events" onClick={closeDrawer}>Find Events</Link>,
                },
                {
                    key: 'create-event',
                    icon: <PlusOutlined />,
                    label: <Link to="/newCreateEvent" onClick={closeDrawer}>Create Event</Link>,
                },
                {
                    key: 'profile',
                    icon: <UserOutlined />,
                    label: <Link to="/profile" onClick={closeDrawer}>Profile</Link>,
                },
                {
                    type: 'divider'
                },
                {
                    key: 'logout',
                    icon: <LogoutOutlined />,
                    danger: true,
                    label: 'Log Out',
                    onClick: logout,
                },
            ].filter(Boolean);
        } else {
            return [
                {
                    key: 'events',
                    icon: <SearchOutlined />,
                    label: <Link to="/events" onClick={closeDrawer}>Find Events</Link>,
                },
                {
                    key: 'signin',
                    icon: <LoginOutlined />,
                    label: 'Sign In',
                    onClick: () => { setButtonSignin(true); closeDrawer(); },
                },
                {
                    key: 'organizer-signin',
                    icon: <LoginOutlined />,
                    label: 'Organizer Sign In',
                    onClick: () => { setOrganizerSignin(true); closeDrawer(); },
                },
            ];
        }
    };


    const getDesktopMenuItems = () => {
        return {
            items: [
                {
                    key: 'profile',
                    label: <Link to="/profile" style={{ 
                        display: 'block',
                        minWidth: '120px',  // Set your desired minimum width
                        
                    }}>Profile</Link>
                },
                {
                    type: 'divider'
                },
                {
                    key: 'logout',
                    label: <div 
                        onClick={logout}
                        style={{ 
                            display: 'block',
                            minWidth: '120px',  // Same minimum width
                            
                        }}
                    >
                        Log Out
                    </div>,
                    danger: true
                }
            ],
            style: {
                minWidth: '120px',  // This sets the minimum width for the entire dropdown
                width: 'auto'
            }
        };
    };

    return (
        <div className="navbar-container">
            <div className="navbar">
                <div className="item1">
                    <Link to="/" style={{ padding: '0', margin: '0', cursor: 'pointer' }}>
                        <img 
                            src={NavbarLogo} 
                            alt="navbar-logo" 
                            style={{ height: '30px', marginTop: '8px', cursor: 'pointer' }} 
                        />
                    </Link>
                </div>

                {showMenu ? (
                    <div className="item3">
                        <ul>
                            {isAdmin && (
                                <li>
                                    <Link to="/adminPanel">
                                        <button className="navb find-events-nav">Admin</button>
                                    </Link>
                                </li>
                            )}
                            {loggedInUser?.data === "Enterprise User" && (
                                <li>
                                    <Link to="/organizerHome">
                                        <button className="navb find-events-nav">Organizer Page</button>
                                    </Link>
                                </li>
                            )}
                            <li>
                                <Link to="/events">
                                    <button className="navb find-events-nav">Find Events</button>
                                </Link>
                            </li>
                            {loggedInUser?.data ? (
                                <>
                                    <li>
                                        <Link to="/newCreateEvent">
                                            <button className="navb create-event-nav">Create Event</button>
                                        </Link>
                                    </li>
                                    <li>
                                        <Dropdown
                                            menu={getDesktopMenuItems()}
                                            placement="bottomRight"
                                            trigger={['click']}
                                            arrow={{ pointAtCenter: true }}
                                        >
                                            <button className="navb nav-prof-photo">
                                                {userInfo?.profilePicture ? (
                                                    <img
                                                        className="profile-photo"
                                                        src={userInfo.profilePicture}
                                                        alt="Profile"
                                                        style={{ width: '32px', height: '32px', borderRadius: '50%' }}
                                                    />
                                                ) : (
                                                    <Avatar className="profile-photo" style={{ width: '32px', height: '32px' }} />
                                                )}
                                            </button>
                                        </Dropdown>
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li>
                                        <button 
                                            className="navb login" 
                                            onClick={() => setButtonSignin(true)}
                                        >
                                            Sign In
                                        </button>
                                    </li>
                                    <li>
                                        <button 
                                            className="navb org-login" 
                                            onClick={() => setOrganizerSignin(true)}
                                        >
                                            Organizer Sign In
                                        </button>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                ) : (
                    <div className="nav-icon-menu">
                        <input 
                            type="checkbox" 
                            id="drawer-toggle" 
                            className="drawer-toggle" 
                            checked={isDrawerOpen} 
                            onChange={toggleDrawer} 
                        />
                        <label htmlFor="drawer-toggle" className="drawer-button">☰</label>
                        <div className={`drawer ${isDrawerOpen ? 'open' : ''}`}>
                            <nav>
                                <Menu
                                    mode="inline"
                                    items={getMobileMenuItems()}
                                    className="mobile-menu-custom"
                                    style={{
                                        border: 'none',
                                        width: 'auto',
                                        background: 'transparent',
                                        fontSize: '16px',
                                    }}
                                />
                            </nav>
                        </div>
                    </div>
                )}
            </div>

            <Signin trigger={buttonSignin} setTrigger={setButtonSignin} />
            <OrganizerSignIn trigger={organizerSignin} setTrigger={setOrganizerSignin} />
        </div>
    );
}

export default Navbar;
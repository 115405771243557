import OrganizerNavbar from "./OrganizerNavbar";
import Footer from "./Footer";
import React, { useState, useEffect, useContext } from 'react';
import { useAppData } from './context/DataContext';

import axios from 'axios';
import { Button, Dropdown, Space, } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ReactComponent as Avatar } from "./IconUmmahfy.svg";
import { ReactComponent as Avatar2 } from "./IconUmmahfyTwo.svg";
import { ReactComponent as Avatar3 } from "./IconUmmahfyThree.svg";
import { ReactComponent as Trash } from "./Trash.svg";
import { ReactComponent as Ellipsis } from "./Ellipsis.svg";

const secrets = require('./secrets.js');
const api = secrets.server_url;

const queryParameters = new URLSearchParams(window.location.search)
const sessionId = queryParameters.get("session_id")

const userTypeItems = [
    {
        key: 'Collaborator',
        label: 'Collaborator',
    },
    {
        key: 'Administrator',
        label: 'Administrator',
    },
    {
        key: 'Co-Owner',
        label: 'Co-Owner',
    },
    {
        key: 'Owner',
        label: 'Owner',
    },
];



function OrganizerSettings() {


    


    const {
    information,
    informationWithPastEvents,
    loggedInUser,
    loggedInEmail,
    isAdmin,
    userInfo,
    userLoc,
    unapprovedEvents,
    selectedOrganization,
    selectedOrganizationInfo,
    managedOrganizations
  } = useAppData();

  

    const [users, setUsers] = useState([
        { email: selectedOrganizationInfo.owner, role: 'Owner', isOwner: true },
        ...(selectedOrganizationInfo.co_owner ? [{ email: selectedOrganizationInfo.co_owner, role: 'Co-Owner', isOwner: false }] : []),
        ...(selectedOrganizationInfo.admins || []).map(admin => ({ ...admin, role: 'Administrator', isOwner: false })),
        ...(selectedOrganizationInfo.collaborators || []).map(collab => ({ ...collab, role: 'Collaborator', isOwner: false }))
    ]);

    const [pendingChange, setPendingChange] = useState(null);
    const [accountCreatePending, setAccountCreatePending] = useState(false);
    const [accountLinkCreatePending, setAccountLinkCreatePending] = useState(false);
    const [error, setError] = useState(false);
    const [connectedAccountId, setConnectedAccountId] = useState();

    const [newUser, setNewUser] = useState('');
    const [newUserType, setNewUserType] = useState('Collaborator');

    useEffect(() => {
        if (sessionId) {
            axios.get(`${api}stripe/retrieve-session/${sessionId}`)
                .then(response => {
                    console.log(response.data)
                    console.log(`Amount Paid: ${response.data.amount_total / 100}`)
                    console.log(`Payment Status: ${response.data.payment_status}`)
                    console.log(`Customer Email: ${response.data.customer_details.email}`)
                    console.log(`Transaction ID: ${response.data.id}`)
                    console.log(`Product Name: ${response.data.line_items.data[0].description}`)
                    console.log(`receipt url: ${response.data.payment_intent.charges.data[0].receipt_url}`)
                })
                .catch(error => {
                    console.error('Unable to retrieve payment details. Please contact support.');
                });
        }
    }, [sessionId]);

    const handleRoleChange = (user, newRole) => {
        setPendingChange({ user, newRole });
    };

    const confirmRoleChange = async () => {
        if (!pendingChange) return;

        const { user, newRole } = pendingChange;
        let data = {};

        // Remove user from their current role
        if (user.role === 'Co-Owner') {
            data.co_owner = "";
        } else if (user.role === 'Administrator') {
            data.admins = selectedOrganizationInfo.admins.filter(admin => admin.email !== user.email);
        } else if (user.role === 'Collaborator') {
            data.collaborators = selectedOrganizationInfo.collaborators.filter(collab => collab.email !== user.email);
        }

        // Add user to their new role
        if (newRole === 'Co-Owner') {
            data.co_owner = user.email;
        } else if (newRole === 'Administrator') {
            data.admins = [...(data.admins || selectedOrganizationInfo.admins), { email: user.email, status: user.status }];
        } else if (newRole === 'Collaborator') {
            data.collaborators = [...(data.collaborators || selectedOrganizationInfo.collaborators), { email: user.email, status: user.status }];
        }

        try {
            await axios.put(`${api}organizations?name=${encodeURIComponent(selectedOrganizationInfo.name)}`, data, {
                headers: {
                    'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY,
                    'Content-Type': 'application/json',
                },
            });

            setUsers(users.map(u =>
                u.email === user.email ? { ...u, role: newRole } : u
            ));

            setPendingChange(null);
        } catch (err) {
            console.error(err);
        }
    };

    const cancelRoleChange = () => {
        setPendingChange(null);
    };

    const removeUser = async (userType, userEmail) => {
        let data = {};
        if (userType === "Collaborator") {
            data.collaborators = selectedOrganizationInfo.collaborators.filter(item => item.email !== userEmail);
        } else if (userType === "Administrator") {
            data.admins = selectedOrganizationInfo.admins.filter(item => item.email !== userEmail);
        } else if (userType === "Co-Owner") {
            data.co_owner = "";
        }

        try {
            //remove user from organization object
            const response = await axios.put(`${api}organizations?name=${encodeURIComponent(selectedOrganizationInfo.name)}`, data, {
                headers: {
                    'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY,
                    'Content-Type': 'application/json',
                },
            });
            console.log(response);

            //remove org from user object
            const response2 = await axios.get(`${api}enterpriseUsers?email=${encodeURIComponent(userEmail)}`, {
                headers: {
                    'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY,
                    'Content-Type': 'application/json',
                },
            });

            const orgsArr = response2.data.data[0].organizations
            const index = orgsArr.indexOf(selectedOrganizationInfo.name);
            if (index !== -1) {
                // Create a new array without modifying the original
                const newArr = [...orgsArr.slice(0, index), ...orgsArr.slice(index + 1)];
                const response3 = await axios.put(`${api}enterpriseUsers?email=${encodeURIComponent(userEmail)}`, {
                    organizations: newArr
                },{
                    headers: {
                        'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY,
                        'Content-Type': 'application/json',
                    },
                });
            }

            // Update local state
            setUsers(users.filter(user => user.email !== userEmail));
        } catch (err) {
            console.error(err);
        }
    };

    const handleAddUser = async (event) => {
        event.preventDefault();

        const userToAdd = {
            email: newUser,
            status: "pending"
        }

        let data = {}

        if (newUserType === "Collaborator") {
            data.collaborators = [...selectedOrganizationInfo.collaborators, userToAdd]
        }
        else if (newUserType === "Administrator") {
            data.admins = [...selectedOrganizationInfo.admins, userToAdd]
        }
        else if (newUserType === "Co-Owner") {
            if (newUser === selectedOrganizationInfo.co_owner || (selectedOrganizationInfo.admins.concat(selectedOrganizationInfo.collaborators)).some(member =>
                member.email === newUser && member.status === 'active'
            )) {
                data.co_owner = newUser
            }
            else {
                console.error("new Co-Owner must first be active user of organizations")
                return;
            }
        }
        else if (newUserType === "Owner") {
            if (newUser === selectedOrganizationInfo.co_owner || (selectedOrganizationInfo.admins.concat(selectedOrganizationInfo.collaborators)).some(member =>
                member.email === newUser && member.status === 'active'
            )) {
                data.owner = newUser
            }
            else {
                console.error("new Owner must first be active user of organizations")
                return;
            }
        }

        try {
            await axios.put(`${api}organizations?name=${encodeURIComponent(selectedOrganizationInfo.name)}`, data, {
                headers: {
                    'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY,
                    'Content-Type': 'application/json',
                },
            });

            const addedUser = await axios.get(`${api}enterpriseUsers?email=${encodeURIComponent(newUser)}`, {
                headers: {
                    'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY,
                    'Content-Type': 'application/json',
                },
            });

            if (addedUser && addedUser.data && addedUser.data.data[0]) {
                try {
                    await axios.post(`${api}inviteToOrg`, {
                        email: newUser,
                        accountType: "Enterprise",
                        requestType: "Invitation",
                        organization: selectedOrganizationInfo.name
                    }, {
                        headers: {
                            'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
                        }
                    })
                }
                catch (err) {
                    console.error(err)
                }
            }
            else {
                await axios.post(`${api}enterpriseUsers`, {
                    email: newUser,
                }, {
                    headers: {
                        'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY,
                        'Content-Type': 'application/json',
                    },
                });

                try {
                    await axios.post(`${api}requestPasswordReset`, {
                        email: newUser,
                        accountType: "Enterprise",
                        requestType: "Activate Account",
                        organization: selectedOrganizationInfo.name
                    }, {
                        headers: {
                            'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY
                        }
                    })
                }
                catch (err) {
                    console.error(err)
                }
            }

            // Update local state
            setUsers([...users, { email: newUser, role: newUserType, isOwner: false, status: "pending" }]);
            setNewUser('');
            setNewUserType('Collaborator');
        }
        catch (err) {
            console.error(err)
        }
    };

    const renderUserRow = (user) => (
        <div className={`user-row ${user.isOwner ? 'user-org-owner' : ''}`} key={user.email}>
            <div className="user-prof-email">
                <Avatar className="user-prof-pic" />
                <p>{user.email}</p>
            </div>
            <div className="user-priv-div">
                {user.isOwner ? (
                    <div className="owner-role-display">
                        {user.role}
                    </div>
                ) : (
                    <Dropdown
                        className="user-collab-manage"
                        menu={{
                            items: [
                                { key: 'Owner', label: 'Owner' },
                                { key: 'Co-Owner', label: 'Co-Owner' },
                                { key: 'Administrator', label: 'Administrator' },
                                { key: 'Collaborator', label: 'Collaborator' },
                            ],
                            onClick: ({ key }) => handleRoleChange(user, key)
                        }}
                        placement="bottom"
                        arrow
                    >
                        <Button>
                            {user.role}
                        </Button>
                    </Dropdown>
                )}

                {pendingChange && pendingChange.user.email === user.email && (
                    <div className="confirm-buttons">
                        <button className="confirm-btn" onClick={confirmRoleChange}>
                            <span className="confirm"> </span>
                        </button>

                        <button className="cancel-btn" onClick={cancelRoleChange}>
                            <span className="cancel"> </span>
                        </button>
                    </div>
                )}
            </div>

            {user.status === "pending" && (
                <div className="user-status">
                    <span className="pending-status">{user.status}</span>
                </div>
            )}

            {user.status === "active" && (
                <div className="user-status">
                    <span className="active-status"> </span>
                </div>
            )}

            {!user.isOwner && (
                <Dropdown
                    className=""
                    placement="bottomRight"
                    menu={{
                        items: [
                            {
                                key: 'Delete',
                                label: 'Delete',
                                onClick: () => removeUser(user.role, user.email)
                            },
                        ],
                        onClick: ({ key }) => {
                            if (key === 'Delete') {
                                removeUser(user.role, user.email);
                            }
                            // You can add more conditions here if you add more items
                        }
                    }}
                >
                    <Button className="user-remove-btn">
                        <Ellipsis className="trash-icon" />
                    </Button>
                </Dropdown>
            )}
        </div>
    );

    const handleMenuClick = (e) => {
        setNewUserType(e.key);
    };

    return (
        <>
            <div className="wrapper">
                <OrganizerNavbar />
                <div className="settings-wrapper">
                    <h1 className="settings-title">Organizer Settings</h1>

                    <div className="sttngs-div">
                        <h2>Users</h2>
                        <p className="user-descr">Manage your organization's users and their roles.</p>
                        <div className="user-table">
                            {users.map(renderUserRow)}
                        </div>

                        <form onSubmit={handleAddUser} >

                            <p class="add-user-title"> Submit User </p>

                            <div className="add-user-row">
                                <input
                                    class="add-user-input"
                                    type="text"
                                    value={newUser}
                                    onChange={(e) => setNewUser(e.target.value)}
                                    placeholder="Enter email"
                                />
                                <Dropdown
                                    className="user-collab-manage"
                                    menu={{
                                        items: userTypeItems,
                                        onClick: handleMenuClick,
                                    }}
                                >
                                    <Button>
                                        <Space>
                                            {newUserType}
                                            <DownOutlined />
                                        </Space>
                                    </Button>
                                </Dropdown>

                                <button class="add-user-btn" type="submit">Add User</button>
                            </div>

                        </form>
                    </div>


                    {/* <select
                value={newUserType}
                onChange={(e) => setNewUserType(e.target.value)}
              >
                <option value="Collaborator">Collaborator</option>
                <option value="Administrator">Administrator</option>
                <option value="Co-Owner">Co-Owner</option>
                <option value="Owner">Owner</option>
              </select> */}
                    {/* ----------------------------------------------------------------------------------------- */}


                    {/* Stripe Account Setup */}
                    <div className="sttngs-div">
                        <h2 className="payment-title">Payments</h2>
                        <div className="container">
                            <div className="payment-content">
                                {selectedOrganizationInfo.stripeAccountId && selectedOrganizationInfo.stripeAccountStatus === "active" && (
                                    <div>
                                        <p className="payment-subtext"> Track your event's finances easily with our Stripe integration.
                                            View ticket sales, revenue, and upcoming payouts all in one place.
                                            Manage refunds and resolve payment issues quickly from your user-friendly dashboard.</p>
                                        <a
                                            href={`https://dashboard.stripe.com/${selectedOrganizationInfo.stripeAccountId}`}
                                            target="_blank"
                                            rel="noopener noreferrer"

                                        >
                                            <button className="join stripe-btn">View Stripe Dashboard</button>
                                        </a>
                                    </div>
                                )}
                                {!selectedOrganizationInfo.stripeAccountId && (
                                    <div>
                                        <p className="payment-subtext"> Connect your Stripe account to start creating paid events.
                                            This allows you to securely collect payments from attendees and manage your event revenue.</p>
                                        <button
                                            className="join stripe-btn"
                                            onClick={async () => {
                                                setAccountCreatePending(true);
                                                setError(false);
                                                try {
                                                    const response = await axios.post(`${api}stripe/account`, {
                                                        name: selectedOrganization,
                                                    }, {
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY,
                                                        }
                                                    });
                                                    const { account } = response.data;
                                                    if (account) {
                                                        setConnectedAccountId(account);
                                                        setAccountLinkCreatePending(true);
                                                        const linkResponse = await axios.post(`${api}stripe/account_link`, {
                                                            account: account,
                                                        }, {
                                                            headers: {
                                                                'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY,
                                                                "Content-Type": "application/json",
                                                            },
                                                        });
                                                        const { url } = linkResponse.data;
                                                        if (url) {
                                                            window.location.href = url;
                                                        }
                                                    }
                                                } catch (error) {
                                                    console.error(error);
                                                    setError(true);
                                                } finally {
                                                    setAccountCreatePending(false);
                                                    setAccountLinkCreatePending(false);
                                                }
                                            }}
                                        >
                                            Connect Stripe Account
                                        </button>
                                    </div>
                                )}
                                {selectedOrganizationInfo.stripeAccountId && selectedOrganizationInfo.stripeAccountStatus === "pending" && (
                                    <>
                                        <p className="payment-subtext">Your Stripe account is awaiting approval.
                                            To complete the process, click here to provide any required additional information.</p>
                                        <button
                                            className="join stripe-btn"
                                            onClick={async () => {
                                                setAccountLinkCreatePending(true);
                                                setError(false);
                                                try {
                                                    const response = await axios.post(`${api}stripe/account_link`, {
                                                        account: selectedOrganizationInfo.stripeAccountId,
                                                    }, {
                                                        headers: {
                                                            'x-api-key': process.env.REACT_APP_UMMAHFY_API_KEY,
                                                            "Content-Type": "application",
                                                            "Content-Type": "application/json",
                                                        },
                                                    });
                                                    const { url } = response.data;
                                                    if (url) {
                                                        window.location.href = url;
                                                    }
                                                } catch (error) {
                                                    console.error(error);
                                                    setError(true);
                                                } finally {
                                                    setAccountLinkCreatePending(false);
                                                }
                                            }}
                                        >
                                            Add Information
                                        </button>
                                    </>
                                )}
                                {error && <p className="error">Something went wrong!</p>}
                                <div className="info-callout">
                                    {/* <p>
                                        This is a sample app for Stripe-hosted Connect onboarding. <a href="https://docs.stripe.com/connect/onboarding/quickstart?connect-onboarding-surface=hosted" target="_blank" rel="noopener noreferrer">View docs</a>
                                    </p> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Payment Link Generation (commented out as per your original code) */}
                    {/* 
          <div className="sttngs-div">
            <h2>Payment Link Generation</h2>
            <button onClick={createPaymentLink}>Generate Payment Link</button>
          </div>
          */}

                </div>
            </div>
            <Footer />
        </>
    );
}

export default OrganizerSettings;
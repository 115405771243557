// src/App.js
import React, { useState, useEffect, useContext } from 'react';
import { useAppData } from './context/DataContext';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { DataContext, DataProvider } from './context/DataContext';
import {
  getBaseData,
  getHomePageEvents,
  getAllEvents,
  getSingleEvent,
  processEssentialData,
  calculateDistance,
  getCurrentEnterpriseUser,
  getSelectedOrgInfo,
  getCurrentUser,
  getAllUsers,
  getAllOrganizations
} from './information.service';

// Your existing component imports
import Home from "./Home";
import Events from "./Events";
import AdminPanel from "./AdminPanel";
import EventShell from "./EventShell";
import Profile from "./Profile";
import OrganizerHome from "./OrganizerHome";
import OrganizerSettings from "./OrganizerSettings";
import Loading from "./Loading";
import ScrollToTop from "./ScrollToTop";
import ErrorPage from "./ErrorPage";
import PasscodeScreen from "./PasscodeScreen";
import NewCreateEvent from "./NewCreateEvent";
import ResetPassword from "./ResetPassword";
import Ticket from "./Ticket";



const redirectToExternalBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (userAgent.match(/Instagram/i)) {
    if (/android/i.test(userAgent)) {
      window.location.href = "intent://www.ummahfy.com#Intent;scheme=https;package=com.android.chrome;end";
    }
  }
};

function AppContent() {
  const location = useLocation();
  const { setAppData } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(true);
  const [startTime, setStartTime] = useState(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [remainingDataLoaded, setRemainingDataLoaded] = useState(false);
  const [minLoadDuration] = useState(500);
  const [maxLoadDuration] = useState(10000);
  const [isDataFetching, setIsDataFetching] = useState(false);
  const [loadStartTime, setLoadStartTime] = useState(null);

  const showLoading = isLoading || (isDataFetching && Date.now() - loadStartTime > minLoadDuration);

  // Step 1: Initial essential data load
  useEffect(() => {
    if (initialLoadComplete) return;

    async function loadInitialData() {
      setStartTime(Date.now());
      try {
        setIsDataFetching(true);
        setLoadStartTime(Date.now());
        
        const baseData = await getBaseData();

        let userInfo = null;
        let orgInfo = {
          selectedOrganization: "",
          selectedOrganizationInfo: null,
          managedOrganizations: []
        };


        if (baseData.loggedInEmail.data) {
          if (baseData.loggedInUser.data === "Enterprise User") {
            const [userData, orgData] = await Promise.all([
              getCurrentEnterpriseUser(baseData.loggedInEmail.data),
              getSelectedOrgInfo(baseData.loggedInEmail.data)
            ]);
            userInfo = userData;
            orgInfo = orgData;
          } else {
            userInfo = await getCurrentUser(baseData.loggedInEmail.data);
          }
        }

        let initialEvents = [];
        if (location.pathname === '/' || location.pathname === '/events') {
          initialEvents = await getHomePageEvents(baseData.userLoc);
        }
        else if (location.pathname.startsWith('/event/')) {
          const eventId = location.pathname.split('/')[2];
          const singleEvent = await getSingleEvent(eventId);
          initialEvents = [singleEvent];
        }

        console.log(initialEvents)

        setAppData({
          ...baseData,
          userInfo,
          ...orgInfo,
          information: initialEvents.filter(item =>
            !item.past &&
            item.isApproved &&
            !item.isPrivate
          ),
          informationWithPastEvents: initialEvents,
          unapprovedEvents: initialEvents.filter(item => !item.isApproved)
        });

      } finally {
        setIsDataFetching(false);
        const elapsedTime = Date.now() - startTime;
        const remainingTime = Math.max(0, minLoadDuration - elapsedTime);

        setTimeout(() => {
          setIsLoading(false);
          setInitialLoadComplete(true);
        }, remainingTime);
      }
    }

    loadInitialData();
  }, []);

  // Step 2: Load remaining data after initial render
  useEffect(() => {
    if (!initialLoadComplete || remainingDataLoaded) return;

    async function loadRemainingData() {
      try {
        console.log('Starting remaining data load');
        setIsDataFetching(true);
        setLoadStartTime(Date.now());
        
        const [allEvents, allUsers, allOrgs] = await Promise.all([
          getAllEvents(),
          getAllUsers(),
          getAllOrganizations()
        ]);

        setAppData(prev => {
          if (prev.userLoc?.lat && prev.userLoc?.lon) {
            allEvents.forEach(event => {
              if (event.latitude && event.longitude) {
                event.distanceFromUser = calculateDistance(
                  prev.userLoc.lat,
                  prev.userLoc.lon,
                  event.latitude,
                  event.longitude
                );
              } else {
                event.distanceFromUser = null;
              }
            });
          }

          let managedOrganizations = prev.managedOrganizations;
          if (prev.userInfo && prev.loggedInUser.data === "Enterprise User") {
            managedOrganizations = allOrgs.filter(org =>
              prev.userInfo.organizations.includes(org.name)
            );
          }

          return {
            ...prev,
            information: allEvents.filter(item =>
              !item.past &&
              item.isApproved &&
              !item.isPrivate
            ),
            informationWithPastEvents: allEvents,
            unapprovedEvents: allEvents.filter(item => !item.isApproved),
            users: allUsers.users,
            enterpriseUsers: allUsers.enterpriseUsers,
            managedOrganizations,
            usersLoaded: true
          };
        });

        setRemainingDataLoaded(true);
      } catch (error) {
        console.error('Error loading remaining data:', error);
      } finally {
        setIsDataFetching(false);
      }
    }

    loadRemainingData();
  }, [initialLoadComplete, remainingDataLoaded]);

  // Maximum loading time handler
  useEffect(() => {
    if (isLoading && startTime) {
      const maxLoadTimeout = setTimeout(() => {
        setIsLoading(false);
        setInitialLoadComplete(true);
      }, maxLoadDuration);

      return () => clearTimeout(maxLoadTimeout);
    }
  }, [isLoading, startTime, maxLoadDuration]);

  // Instagram redirect
  useEffect(() => {
    redirectToExternalBrowser();
  }, []);

  return (
    <>
      {showLoading ? (
        <Loading />
      ) : (
        <>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/events" element={<Events />} />
            <Route path="/event/:id" element={<EventShell />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/adminPanel" element={<AdminPanel />} />
            <Route path="/organizerHome" element={<OrganizerHome />} />
            <Route path="/organizerSettings" element={<OrganizerSettings />} />
            <Route path="/newCreateEvent" element={<NewCreateEvent />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/ticket" element={<Ticket />} />
          </Routes>
        </>
      )}
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <DataProvider>
        <AppContent />
      </DataProvider>
    </BrowserRouter>
  );
}

export default App;